import React, { useState } from 'react';
import './Home.css';

import logo from '../logo.png';
import logoInverted from '../logo_inverted.png';

import { getIconSvg } from '../Icons';

function Home({ theme, setSection, viewport }) {
	setSection('home');

	const [isLogoPressed, setIsLogoPressed] = useState(false);

	const [quote, setQuote] = useState('');
	const [author, setAuthor] = useState('');

	const openCV = () => window.open('/docs/CV-EN.pdf', '_blank');

	// TODO Set display to true when new CV is ready
	const displayCV = false;

	return (
		<section className="home">
			<div>
				<Introduction theme={theme} quote={quote} author={author} />
			</div>
			<div>
				<LogoButton
					theme={theme}
					isLogoPressed={isLogoPressed}
					setQuote={setQuote}
					setAuthor={setAuthor}
					setIsLogoPressed={setIsLogoPressed}
				/>
				<CVButton
					display={displayCV}
					onPressed={openCV}
					theme={theme}
				/>
				<span className={`made-by ${theme.name}`}>
					Made By{' '}
					<span role="img" aria-label="icon">
						🦊
					</span>
				</span>
			</div>
		</section>
	);
}

function Introduction({ quote, author, theme }) {
	let quoteComponent = null;
	if (!!quote && !!author) {
		quoteComponent = (
			<div className="quote">
				<p>{quote}</p>
				<cite>- {author}</cite>
			</div>
		);
	}

	return (
		<div className={`introduction ${theme.name}`}>
			<span>Hi</span>
			<br />
			<span>I'm Jose</span>
			<p>
				I'm a Software Engineer from Panama and i like to building cool
				stuff
			</p>
			{quoteComponent}
		</div>
	);
}

function LogoButton({
	theme,
	isLogoPressed,
	setQuote,
	setAuthor,
	setIsLogoPressed,
}) {
	const themeClass = theme.name === 'dark' ? 'dark' : '';
	const isPressedClass = isLogoPressed ? 'pressed' : '';

	const url = 'https://programming-quotes-api.herokuapp.com/quotes/random';

	const onLogoPressed = () => {
		if (!isLogoPressed) {
			fetch(url)
				.then((response) => response.json())
				.then((data) => {
					const { en, author } = data;
					const quoteOfTheDay = document.querySelector(
						'#quote-of-the-day'
					);

					quoteOfTheDay.setAttribute('content', `${author} | ${en}`);

					setQuote(en);
					setAuthor(author);
				});
			setIsLogoPressed(true);
			setTimeout(() => {
				setIsLogoPressed(false);
			}, 250);
		}
	};

	return (
		<button
			aria-label="logo"
			className={`logo-button ${themeClass} ${isPressedClass}`}
			onClick={onLogoPressed}
		>
			<img src={theme.name === 'dark' ? logoInverted : logo} alt="Logo" />
		</button>
	);
}

function CVButton({ theme, onPressed, display }) {
	const [isPressed, setIsPressed] = useState(false);

	if (!display) {
		return null;
	}

	const themeClass = theme.name === 'dark' ? 'dark' : '';
	const isPressedClass = isPressed ? 'pressed' : '';

	const onButtonPressed = () => {
		if (!isPressed) {
			onPressed();
			setIsPressed(true);
			setTimeout(() => {
				setIsPressed(false);
			}, 250);
		}
	};

	return (
		<button
			aria-label="cv"
			className={`cv-button ${themeClass} ${isPressedClass}`}
			onClick={onButtonPressed}
		>
			{getIconSvg('download', theme)}
		</button>
	);
}

export default Home;
