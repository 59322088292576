import React from 'react';
import './Contact.css';
import { getIconSvg } from '../Icons';

function Contact({ theme, setSection, viewPort }) {
	console.log(`Viewport: ${viewPort}`);
	setSection('contact');

	const nameRef = React.createRef();
	const emailRef = React.createRef();
	const phoneRef = React.createRef();
	const subjectRef = React.createRef();
	const messageRef = React.createRef();

	const resetForm = () => {
		try {
			document.getElementById("contact-me").reset(); 
		} catch (e) {
			console.error(e);
		}
	};

	const onFormSubmit = (e) => {
		e.preventDefault();
		const request = {
			method: 'POST',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				name: nameRef.current.value,
				email: emailRef.current.value,
				phone: phoneRef.current.value,
				subject: subjectRef.current.value,
				message: messageRef.current.value,
			}),
		};

		fetch('/contact', request)
			.then((res) => res.json())
			.then((data) => {
				if (data.success) {
					resetForm();
					alert('Your message has been successfully sent');
				}
			})
			.catch((err) => console.error(err));
	};

	return (
		<section className={`contact ${theme.name}`}>
			<div>
				<span>Contact</span>
			</div>
			<div>
				<div>
					<form id="contact-me" onSubmit={onFormSubmit}>
						<input
							type="text"
							placeholder="Name"
							ref={nameRef}
							required
						/>
						<div className="details">
							<input
								type="email"
								placeholder="Email"
								ref={emailRef}
								required
							/>
							<input
								type="tel"
								placeholder="Phone"
								ref={phoneRef}
							/>
						</div>
						<input
							type="text"
							placeholder="Subject"
							ref={subjectRef}
							required
						/>
						<textarea
							type="text"
							placeholder="Message"
							ref={messageRef}
							required
						/>
						{viewPort === 'desktop' ? (
							<button type="submit">
								{getIconSvg('send', theme)}
							</button>
						) : null}
					</form>
				</div>
				{viewPort === 'mobile' ? (
					<button type="submit" form="contact-me">
						{getIconSvg('send', theme)}
					</button>
				) : null}
				<span className={`made-by ${theme.name}`}>
					Made By{' '}
					<span role="img" aria-label="icon">
						🦊
					</span>
				</span>
			</div>
		</section>
	);
}

export default Contact;
