import React from 'react';

export function getIconSvg(name, theme) {
	const themeName = theme.name;
	const iconStrokeColor = theme.color;
	switch (name) {
		case 'home':
			return (
				<svg
					xmlns="http://www.w3.org/2000/svg"
					width="24"
					height="24"
					viewBox="0 0 24 24"
					fill="none"
					stroke={iconStrokeColor}
					strokeWidth="2"
					strokeLinecap="round"
					strokeLinejoin="round"
					className={`feather ${themeName}`}
				>
					{' '}
					<path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path>
					<polyline points="9 22 9 12 15 12 15 22"></polyline>
				</svg>
			);
		case 'twitter':
			return (
				<svg
					xmlns="http://www.w3.org/2000/svg"
					width="24"
					height="24"
					viewBox="0 0 24 24"
					fill="none"
					stroke={iconStrokeColor}
					strokeWidth="2"
					strokeLinecap="round"
					strokeLinejoin="round"
					className={`feather ${themeName}`}
				>
					<path d="M23 3a10.9 10.9 0 0 1-3.14 1.53 4.48 4.48 0 0 0-7.86 3v1A10.66 10.66 0 0 1 3 4s-4 9 5 13a11.64 11.64 0 0 1-7 2c9 5 20 0 20-11.5a4.5 4.5 0 0 0-.08-.83A7.72 7.72 0 0 0 23 3z"></path>
				</svg>
			);
		case 'github':
			return (
				<svg
					xmlns="http://www.w3.org/2000/svg"
					width="24"
					height="24"
					viewBox="0 0 24 24"
					fill="none"
					stroke={iconStrokeColor}
					strokeWidth="2"
					strokeLinecap="round"
					strokeLinejoin="round"
					className={`feather ${themeName}`}
				>
					<path d="M9 19c-5 1.5-5-2.5-7-3m14 6v-3.87a3.37 3.37 0 0 0-.94-2.61c3.14-.35 6.44-1.54 6.44-7A5.44 5.44 0 0 0 20 4.77 5.07 5.07 0 0 0 19.91 1S18.73.65 16 2.48a13.38 13.38 0 0 0-7 0C6.27.65 5.09 1 5.09 1A5.07 5.07 0 0 0 5 4.77a5.44 5.44 0 0 0-1.5 3.78c0 5.42 3.3 6.61 6.44 7A3.37 3.37 0 0 0 9 18.13V22"></path>
				</svg>
			);
		case 'linkedIn':
			return (
				<svg
					xmlns="http://www.w3.org/2000/svg"
					width="24"
					height="24"
					viewBox="0 0 24 24"
					fill="none"
					stroke={iconStrokeColor}
					strokeWidth="2"
					strokeLinecap="round"
					strokeLinejoin="round"
					className={`feather ${themeName}`}
				>
					<path d="M16 8a6 6 0 0 1 6 6v7h-4v-7a2 2 0 0 0-2-2 2 2 0 0 0-2 2v7h-4v-7a6 6 0 0 1 6-6z"></path>
					<rect x="2" y="9" width="4" height="12"></rect>
					<circle cx="4" cy="4" r="2"></circle>
				</svg>
			);
		case 'sun':
			return (
				<svg
					xmlns="http://www.w3.org/2000/svg"
					width="24"
					height="24"
					viewBox="0 0 24 24"
					fill="none"
					stroke={iconStrokeColor}
					strokeWidth="2"
					strokeLinecap="round"
					strokeLinejoin="round"
					className={`feather ${themeName}`}
				>
					<circle cx="12" cy="12" r="5"></circle>
					<line x1="12" y1="1" x2="12" y2="3"></line>
					<line x1="12" y1="21" x2="12" y2="23"></line>
					<line x1="4.22" y1="4.22" x2="5.64" y2="5.64"></line>
					<line x1="18.36" y1="18.36" x2="19.78" y2="19.78"></line>
					<line x1="1" y1="12" x2="3" y2="12"></line>
					<line x1="21" y1="12" x2="23" y2="12"></line>
					<line x1="4.22" y1="19.78" x2="5.64" y2="18.36"></line>
					<line x1="18.36" y1="5.64" x2="19.78" y2="4.22"></line>
				</svg>
			);
		case 'user':
			return (
				<svg
					xmlns="http://www.w3.org/2000/svg"
					width="24"
					height="24"
					viewBox="0 0 24 24"
					fill="none"
					stroke={iconStrokeColor}
					strokeWidth="2"
					strokeLinecap="round"
					strokeLinejoin="round"
					className={`feather ${themeName}`}
				>
					<path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
					<circle cx="12" cy="7" r="4"></circle>
				</svg>
			);
		case 'skills':
			return (
				<svg
					xmlns="http://www.w3.org/2000/svg"
					width="24"
					height="24"
					viewBox="0 0 24 24"
					fill="none"
					stroke={iconStrokeColor}
					strokeWidth="2"
					strokeLinecap="round"
					strokeLinejoin="round"
					className={`feather ${themeName}`}
				>
					<circle cx="12" cy="8" r="7"></circle>
					<polyline points="8.21 13.89 7 23 12 20 17 23 15.79 13.88"></polyline>
				</svg>
			);
		case 'projects':
			return (
				<svg
					xmlns="http://www.w3.org/2000/svg"
					width="24"
					height="24"
					viewBox="0 0 24 24"
					fill="none"
					stroke={iconStrokeColor}
					strokeWidth="2"
					strokeLinecap="round"
					strokeLinejoin="round"
					className={`feather ${themeName}`}
				>
					<polyline points="16 18 22 12 16 6"></polyline>
					<polyline points="8 6 2 12 8 18"></polyline>
				</svg>
			);
		case 'contact':
			return (
				<svg
					xmlns="http://www.w3.org/2000/svg"
					width="24"
					height="24"
					viewBox="0 0 24 24"
					fill="none"
					stroke={iconStrokeColor}
					strokeWidth="2"
					strokeLinecap="round"
					strokeLinejoin="round"
					className={`feather ${themeName}`}
				>
					<path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path>
					<polyline points="22,6 12,13 2,6"></polyline>
				</svg>
			);
		case 'download':
			return (
				<svg
					xmlns="http://www.w3.org/2000/svg"
					width="100%"
					height="100%"
					viewBox="0 0 24 24"
					fill="none"
					stroke={iconStrokeColor}
					strokeWidth="2"
					strokeLinecap="round"
					strokeLinejoin="round"
					className={`feather ${themeName}`}
				>
					<path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
					<polyline points="7 10 12 15 17 10"></polyline>
					<line x1="12" y1="15" x2="12" y2="3"></line>
				</svg>
			);
		case 'moon':
			return (
				<svg
					xmlns="http://www.w3.org/2000/svg"
					width="24"
					height="24"
					viewBox="0 0 24 24"
					fill="none"
					stroke={iconStrokeColor}
					strokeWidth="2"
					strokeLinecap="round"
					strokeLinejoin="round"
					className={`feather ${themeName}`}
				>
					<path d="M21 12.79A9 9 0 1 1 11.21 3 7 7 0 0 0 21 12.79z"></path>
				</svg>
			);
		case 'send':
			return (
				<svg
					xmlns="http://www.w3.org/2000/svg"
					width="24"
					height="24"
					viewBox="0 0 24 24"
					fill="none"
					stroke={iconStrokeColor}
					strokeWidth="2"
					strokeLinecap="round"
					strokeLinejoin="round"
					className={`feather ${themeName}`}
				>
					<line x1="22" y1="2" x2="11" y2="13"></line>
					<polygon points="22 2 15 22 11 13 2 9 22 2"></polygon>
				</svg>
			);
		default:
			return null;
	}
}
