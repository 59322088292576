import React, { useState } from 'react';
import { Route, Link, Switch } from 'react-router-dom';

import './App.css';

import { getIconSvg } from './Icons';

import Home from './sections/Home';
import About from './sections/About';
import Skills from './sections/Skills';
import Projects from './sections/Projects';
import Contact from './sections/Contact';

const openUrl = (url) => window.open(url, '_blank');

const openGithub = () => openUrl('https://github.com/jjzcru');
const openTwitter = () => openUrl('https://twitter.com/jjzcru');
const openLinkedIn = () => openUrl('https://www.linkedin.com/in/jjzcru');

function App() {
	const [viewPort, setViewPort] = useState(getViewPort());
	const [section, setSection] = useState(getSection());
	const [themeName, setThemeName] = useState(getInitialTheme());

	window.addEventListener('resize', () => setViewPort(getViewPort()));

	const favicon = document.querySelector('#favicon');
	if (themeName === 'dark') {
		favicon.setAttribute('href', '/favicon.ico');
	} else {
		favicon.setAttribute('href', '/favicon_inverted.ico');
	}

	const toggleTheme = () =>
		setThemeName(themeName === 'light' ? 'dark' : 'light');

	const theme = getTheme(themeName);

	document.body.style.background = theme.background;

	return (
		<div
			className="App"
			style={{ background: theme.background, color: theme.color }}
		>
			<Topbar theme={theme} toggleTheme={toggleTheme} title={section} />
			<div className="content">
				<Sidebar theme={theme} toggleTheme={toggleTheme} />
				<Main
					theme={theme}
					setSection={setSection}
					viewPort={viewPort}
				/>
			</div>
			<Footer theme={theme} section={section} />
		</div>
	);
}

function Topbar({ theme, toggleTheme, title }) {
	const themeIcon = theme.name === 'light' ? 'sun' : 'moon';
	return (
		<header className={theme.name}>
			<button aria-label="theme" onClick={toggleTheme}>
				{getIconSvg(themeIcon, theme)}
			</button>
			<h1>{title}</h1>
		</header>
	);
}

function Sidebar({ theme, toggleTheme }) {
	const sideBarTheme =
		theme.name === 'light' ? 'sidebar-light' : 'sidebar-dark';
	const themeIcon = theme.name === 'light' ? 'sun' : 'moon';
	return (
		<aside className={sideBarTheme}>
			<button aria-label="theme" onClick={toggleTheme}>
				{getIconSvg(themeIcon, theme)}
			</button>
			<div className="sidebar-sections">
				<button aria-label="home">
					<Link aria-label="home" to="/">
						{getIconSvg('home', theme)}
					</Link>
				</button>
				<button aria-label="user">
					<Link aria-label="user" to="/about">
						{getIconSvg('user', theme)}
					</Link>
				</button>
				<button aria-label="skills">
					<Link aria-label="skills" to="/skills">
						{getIconSvg('skills', theme)}
					</Link>
				</button>
				<button aria-label="projects">
					<Link aria-label="projects" to="/projects">
						{getIconSvg('projects', theme)}
					</Link>
				</button>
				<button aria-label="contact">
					<Link aria-label="contact" to="/contact">
						{getIconSvg('contact', theme)}
					</Link>
				</button>
			</div>
			<div className="social">
				<button aria-label="github" onClick={openGithub}>
					{getIconSvg('github', theme)}
				</button>
				<button aria-label="twitter" onClick={openTwitter}>
					{getIconSvg('twitter', theme)}
				</button>
				<button aria-label="linked-in" onClick={openLinkedIn}>
					{getIconSvg('linkedIn', theme)}
				</button>
			</div>
		</aside>
	);
}

function Main({ theme, setSection, viewPort }) {
	console.log(`ViewPort: ${viewPort}`);
	return (
		<main>
			<Switch>
				<Route
					exact
					path="/"
					component={() => (
						<Home
							theme={theme}
							setSection={setSection}
							viewPort={viewPort}
						/>
					)}
				/>
				<Route
					path="/about"
					component={() => (
						<About
							theme={theme}
							setSection={setSection}
							viewPort={viewPort}
						/>
					)}
				/>
				<Route
					path="/skills"
					component={() => (
						<Skills
							theme={theme}
							setSection={setSection}
							viewPort={viewPort}
						/>
					)}
				/>
				<Route
					path="/projects"
					component={() => (
						<Projects
							theme={theme}
							openUrl={openUrl}
							setSection={setSection}
							viewPort={viewPort}
						/>
					)}
				/>
				<Route
					path="/contact"
					component={() => (
						<Contact
							theme={theme}
							setSection={setSection}
							viewPort={viewPort}
						/>
					)}
				/>
			</Switch>
		</main>
	);
}

function Footer({ theme, section }) {
	theme.accent = theme.color;

	let skillsButton = (
		<button
			aria-label="skills"
			className={section === 'skills' ? 'pressed' : ''}
		>
			<Link aria-label="skills" to="/skills">
				{getIconSvg('skills', theme)}
			</Link>
		</button>
	);

	skillsButton = null;


	return (
		<footer className={theme.name}>
			<nav>
				<button
					aria-label="home"
					className={section === 'home' ? 'pressed' : ''}
				>
					<Link aria-label="home" to="/">
						{getIconSvg('home', theme)}
					</Link>
				</button>
				<button
					aria-label="user"
					className={section === 'about' ? 'pressed' : ''}
				>
					<Link aria-label="user" to="/about">
						{getIconSvg('user', theme)}
					</Link>
				</button>
				{skillsButton}
				<button
					aria-label="projects"
					className={section === 'projects' ? 'pressed' : ''}
				>
					<Link aria-label="projects" to="/projects">
						{getIconSvg('projects', theme)}
					</Link>
				</button>
				<button
					aria-label="contact"
					className={section === 'contact' ? 'pressed' : ''}
				>
					<Link aria-label="contact" to="/contact">
						{getIconSvg('contact', theme)}
					</Link>
				</button>
			</nav>
		</footer>
	);
}

function getViewPort() {
	return getViewPortWidth() > 600 ? 'desktop' : 'mobile';
}

function getViewPortWidth() {
	return Math.max(
		document.documentElement.clientWidth || 0,
		window.innerWidth || 0
	);
}

function getSection() {
	switch (window.location.pathname) {
		case '/skills':
			return 'skills';
		case '/about':
			return 'about';
		case '/projects':
			return 'projects';
		case '/contact':
			return 'contact';
		default:
			return 'home';
	}
}

function getInitialTheme() {
	const d = new Date();
	const hour = d.getHours();
	if (hour > 6 && hour < 18) {
		return 'light';
	}

	return 'dark';
}

function getTheme(name) {
	const theme = {
		name: 'light',
		accent: '#00B2B3',
		background: '#ffffff',
		color: '#323753',
	};
	switch (name) {
		case 'dark':
			theme.name = 'dark';
			theme.accent = '#00FFFF';
			theme.background = '#323753';
			theme.color = '#ffffff';
			break;
		default:
			return theme;
	}

	return theme;
}

export default App;
