import React from 'react';
import './Skills.css';

function Skills({ theme, setSection, viewport }) {
	setSection('skills');
	return (
		<section className={`skills ${theme.name}`}>
			<div>
				<span>Skills</span>
			</div>
			<div>
				<div className={`description ${theme.name}`}>
					<div>
						<p>
							My last couple of years my main area of work has
							been around the JS ecosystem: Typescript, NodeJS,
							ES6+ and more recently React, GraphQL and PWA.
						</p>
						<p>
							But on the side I like creating cli tools with Go,
							and also I offer services as a freelance, right now
							I have been working with an agency, I have also work
							creating a Android framework for a design company
							that enables designer to create android apps just
							using images without any Android knowledge.
						</p>
					</div>
				</div>
				<div className={`sections ${theme.name}`}>
					<div>
						<h2>Back-end</h2>
						<ul>
							<li>Typescript</li>
							<li>NodeJS</li>
							<li>Go</li>
							<li>GraphQL</li>
							<li>HTML/CSS</li>
							<li>SQL</li>
							<li>REST</li>
						</ul>
					</div>
					<div>
						<h2>Front-end</h2>
						<ul>
							<li>React</li>
							<li>JS (ES6+)</li>
							<li>HTML</li>
							<li>CSS</li>
							<li>Kotlin (Android)</li>
						</ul>
					</div>
					<div>
						<h2>DevOps</h2>
						<ul>
							<li>Jenkins</li>
							<li>Github Actions</li>
							<li>Travis CI</li>							
							<li>Git</li>
						</ul>
					</div>
				</div>
				<span className={`made-by ${theme.name}`}>
					Made By{' '}
					<span role="img" aria-label="icon">
						🦊
					</span>
				</span>
			</div>
		</section>
	);
}

export default Skills;
