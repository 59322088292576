import React from 'react';
import './About.css';

function About({ theme, setSection, viewport }) {
	setSection('about');
	return (
		<section className={`about ${theme.name}`}>
			<div>
				<span>About</span>
			</div>
			<div>
				<div>
					<p>Hi! I’m Jose Cruz, a software engineer from Panama.</p>
					<p>
						I like creating tools that help me automize my workflow,
						either that be a CLI, a script, an app or a web
						application. My goals is to create tools that are simple
						to use but flexible enough that I can join them with my
						existing workflow.
					</p>
					<p>
						I start programming since my teenage years, I started
						with a LAMP Stack (with PHP) then I study software
						engineer in The Technological University of Panama.
					</p>
					<p>
						During my studies I work part time as a freelancer,
						focus mostly on JS (Front-end and Back-end). Right now I
						work for the company Canvasflow as a software developer
						with a wide variety of technologies some of them are:
						<ul>
							<li>GraphQL</li>
							<li>Wordpress Plugins</li>
							<li>InDesign Plugins</li>
							<li>Micro-services</li>
							<li>CI/CD</li>
						</ul>
					</p>
				</div>

				<span className={`made-by ${theme.name}`}>
					Made By{' '}
					<span role="img" aria-label="icon">
						🦊
					</span>
				</span>
			</div>
		</section>
	);
}

export default About;
