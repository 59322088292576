import React, { useState } from 'react';
import './Projects.css';

function Projects({ theme, openUrl, setSection, viewport }) {
	setSection('projects');
	const [index, setIndex] = useState(0);
	const projects = getProjects();
	const selectedProject = projects[index];

	return (
		<section className={`projects ${theme.name}`}>
			<div>
				<span>Projects</span>
			</div>
			<div>
				<div>
					<div className="display">
						<h1>
							{`${selectedProject.name} `}
							<span
								role="img"
								aria-label="icon"
								className="project-icon"
								onClick={() => openUrl(selectedProject.github)}
							>
								{selectedProject.emoji}
							</span>
						</h1>
						{selectedProject.paragraphs.map((paragraph, i) => (
							<p key={i}>{paragraph}</p>
						))}
					</div>
				</div>
				<div>
					{projects.map((project, i) => (
						<ProjectButton
							key={i}
							project={project}
							index={i}
							isPressed={index === i}
							setIndex={setIndex}
						/>
					))}
					<span className={`made-by ${theme.name}`}>
						Made By{' '}
						<span role="img" aria-label="icon">
							🦊
						</span>
					</span>
				</div>
			</div>
		</section>
	);
}

function getProjects() {
	return [
		{
			emoji: '🦌',
			name: 'Elk',
			github: 'https://github.com/jjzcru/elk',
			paragraphs: [
				`This is a cli task runner that lets you automize commands using a yaml file to declare the steps 
                required to execute the task.`,
				`The main idea behind this is to run something flexible like npm run but instead of running them 
                locally I wanted to be able to run it from any path from my terminal.`,
				`But that wasn’t enough, I also wanted to be able to execute those commands remotely, so added a 
                graphql functionality that enables you to execute task remotely as long as you were able to talk 
                with the graphql server.`,
			],
		},
		{
			emoji: '🐗',
			name: 'Hog',
			github: 'https://github.com/jjzcru/hog',
			paragraphs: [
				`This is a cli that enables you to share files directly from your machine. The reason for why I 
                created this project is because I want to share files from my machine to my cellphone but I didn’t 
                want to upload them to a service to later download it from my phone, I just wanted to be able to 
                download them directly from my machine.`,
				`So with this tool you just select a path from your file system and I will serve them from an 
                endpoint, the tool also generate a qr code with the link to download.`,
				`I just need to add a file, or files, to the cli and scan the QR code from my cellphone.`,
			],
		},
		{
			emoji: '🎒',
			name: 'Swagger UI Loader',
			github: 'https://github.com/jjzcru/swagger-ui-loader',
			paragraphs: [
				`As a freelance I need to delivered documentation to my clients for their endpoints, for that I 
                declare it as a OpenApi document, but to open them you need to upload the file to something like 
                Swagger Editor.`,
				`So I created an npm package that you just need to target the address from the file and it will 
                mount an express server to run the Swagger Editor right there from the machine.`,
			],
		},
		{
			emoji: '🗺',
			name: 'RTLS Demo',
			github: 'https://github.com/jjzcru/rtls-demo',
			paragraphs: [
				`This is a weekend project that I put together where I wanted to emulate a RTLS using docker, 
                Redis and web sockets.`,
				`This project will emulate random devices that you are able to see in a browser window where they 
                are located in real time using Socket.IO and OpenStreetMap to display the markers for the devices.`,
			],
		},
	];
}

function ProjectButton({ project, isPressed, index, setIndex }) {
	const { name, emoji } = project;
	/*

	*/
	return (
		<div
			className={`project ${isPressed ? 'pressed' : ''}`}
			onClick={() => setIndex(index)}
		>
			<div>
				<div>
					<span role="img" aria-label="icon">
						{emoji}
					</span>
					<h4>{name}</h4>
				</div>
			</div>
		</div>
	);
}

export default Projects;
